body{
  background-color: #1f1e1e;
  color: white;
  overflow: hidden;
}

h1{
  text-align: center;
}

.todo-wrapper{
  background: #353434;
  padding: 2%;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 5px 7px rgb(12, 12, 12);
}

.todo-input{
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid gray;
  padding-bottom: 25px;
  margin-bottom: 25px;

}

.todo-input-item{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 25px;
}

.todo-input-item label{
  font-weight: bold;
  margin-bottom: 10px;
}

.todo-input-item input{
  padding: 8px ;
  border: none;
  width: 250px;

}

.todo-input-item input:focus{
  
  outline: 2px solid rgb(0,233,120);
}

.primaryBtn{
  background-color: rgb(0,230,122);
  color: white;
  border: none;
  border-radius: 0px;
  margin-top: 25px;
  padding: 10px;
  width: 60px;
  cursor: pointer;
}

.primaryBtn:hover{
  background-color: rgb(4, 196, 106);
}

.secondaryBtn{
  background-color: rgb(71,71,71);
  color: white;
  border: none;
  border-radius: 0px;
  margin-top: 25px;
  padding: 10px;
  width: fit-content;
  cursor: pointer;
}

.active{
  background-color: rgb(0,230, 122);
}

.btn-area{
  margin-bottom: 15px;
}

.todo-list{
  display: flex;
  flex-direction: column;

}

.todo-list-items{
  background-color: #414040;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 3px 5px rgba(43, 42,42);
}

.todo-list-items h3{
  font-size: 25px;
  color: rgb(0, 230, 122);
  margin: 0px;
  font-weight: bold;
}

.todo-list-items p{
  font-size: 14px;
  color: rgb(161,161,161);
  margin-top: 8px;
}

.icon{
  font-size: 35px;
  cursor: pointer;
}

.check-icon{
  font-size: 25px;
  margin-left: 10px;
  color: rgb(0, 230, 122);
  cursor: pointer;
}

.icon:hover{
  color: red;
}

.check-icon:hover{
  color: rgb(4, 196, 106);;
}